// initialize the subTestId arrays, used to determine which test to display
export const multipleChoiceTests: string[] = [
  "1",
  "4",
  "21",
  "22",
  "27",
  "28",
  "29",
  "33",
  "39",
  "40",
  "41",
  "42",
  "44",
  "45",
  "55",
  "56",
  "57",
  "58",
  "59",
  "65",
  "67",
  "68",
  "69",
  "70",
  "71",
  "72",
  "73",
  "74",
  "75",
  "76",
  "77",
  "78",
  "79",
  "83",
  "84",
  "85",
  "86",
  "87",
  "93",
  "94",
  "95",
  "96",
  "97",
  "100",
  "101",
  "102",
  "103",
  "104",
  "105",
  "106",
  "107",
  "108",
  "111",
  "113",
  "114",
  "115",
  "116",
  "118",
  "124",
  "125",
  "126",
  "127",
  "128",
  "129",
  "130",
  "131", // adaptive CCAT
  "133",
  "134",
  "142",
  "177",
  "178",
  "187",
  "222",
  "223",
  "225",
  "226",
  "227",
  "231",
  "232",
  "234",
  "235"
  // LAST LINE - DO NOT MOVE THIS COMMENT multipleChoiceTests
];

export const personalityTests: string[] = [
  "7",
  "19",
  "20",
  "35",
  "43",
  "52",
  "88",
  "89",
  "90",
  "91",
  "98",
  "99",
  "152",
  "156",
  "157",
  "155",
  "185",
  "186",
  "216",
  "217",
  "218",
  "219",
  "220",
  "221",
  "224",
  "228",
  "233"
  // LAST LINE - DO NOT MOVE THIS COMMENT personalityTests
];

export const gameTests: string[] = [
  "92",
  "117",
  "165"
  // LAST LINE - DO NOT MOVE THIS COMMENT gameTests
];

export const typingTests: string[] = [
  "23"
  // LAST LINE - DO NOT MOVE THIS COMMENT typingTests
];

export const tenKeyTests: string[] = [
  "24"
  // LAST LINE - DO NOT MOVE THIS COMMENT tenKeyTests
];

export const mrabCastTests: string[] = [
  "10",
  "12",
  "13",
  "18"
  // LAST LINE - DO NOT MOVE THIS COMMENT mrabCastTests
];

export const CLIKTests: string[] = [
  "34",
  "135"
  // LAST LINE - DO NOT MOVE THIS COMMENT CLIKTests
];

export const nonMobileTests: string[] = [
  "23",
  "24",
  "34",
  "135"
  // LAST LINE - DO NOT MOVE THIS COMMENT nonMobileTests
];

export const rcatTests: string[] = [
  "188",
  "189"
  // LAST LINE - DO NOT MOVE THIS COMMENT rcatTests
];

export const revelianSubTests = [
  "109",
  "110",
  "112",
  "119",
  "120",
  "121",
  "122",
  "123",
  "132",
  "136",
  "137",
  "138",
  "139",
  "140",
  "141",
  "143",
  "144",
  "145",
  "146",
  "150",
  "151",
  "153",
  "154",
  "158",
  "159",
  "160",
  "161",
  "162",
  "163",
  "164",
  "166",
  "167",
  "168",
  "169",
  "170",
  "171",
  "172",
  "173",
  "174",
  "175",
  "176",
  "179",
  "180",
  "181",
  "182",
  "183",
  "184",
  "188",
  "189",
  "190",
  "191",
  "192",
  "193",
  "194",
  "195",
  "196",
  "197",
  "198",
  "199",
  "200",
  "201",
  "202",
  "203",
  "204",
  "205",
  "206",
  "207",
  "208",
  "209",
  "210",
  "211",
  "212",
  "213",
  "214",
  "215",
  "229",
  "230"
  // LAST LINE - DO NOT MOVE THIS COMMENT revelianSubTests
];

export const getAllTests = () => {
  const allTestsLibrary: { [key: string]: string } = {};
  multipleChoiceTests.forEach(
    testId => (allTestsLibrary[testId] = "multipleChoiceTest")
  );
  personalityTests.forEach(
    testId => (allTestsLibrary[testId] = "personalityTest")
  );
  gameTests.forEach(testId => (allTestsLibrary[testId] = "gameTest"));
  typingTests.forEach(testId => (allTestsLibrary[testId] = "typingTest"));
  tenKeyTests.forEach(testId => (allTestsLibrary[testId] = "tenKeyTest"));
  mrabCastTests.forEach(testId => (allTestsLibrary[testId] = "mrabCastTest"));
  CLIKTests.forEach(testId => (allTestsLibrary[testId] = "CLIKTest"));
  revelianSubTests.forEach(
    testId => (allTestsLibrary[testId] = "revelianSubTest")
  );
  return allTestsLibrary;
};

export const revelianTestToSubTestIdMap = [
  {
    name: "emotify",
    subTestIds: [
      "109",
      "112",
      "137"
      // LAST LINE - DO NOT MOVE THIS COMMENT emotify
    ]
  },
  {
    name: "cognify",
    subTestIds: [
      "110",
      "121",
      "122",
      "123",
      "138",
      "139",
      "140",
      "141",
      "143",
      "144",
      "145",
      "146",
      "150",
      "151",
      "153",
      "154",
      "158",
      "159",
      "160",
      "161",
      "162",
      "163",
      "164",
      "166",
      "167",
      "168",
      "169",
      "170",
      "173",
      "174",
      "183",
      "184"
      // LAST LINE - DO NOT MOVE THIS COMMENT cognify
    ]
  },
  {
    name: "safety",
    subTestIds: [
      "119",
      "120",
      "171",
      "172",
      "175",
      "176",
      "229",
      "230"
      // LAST LINE - DO NOT MOVE THIS COMMENT safety
    ]
  },
  {
    name: "personality",
    subTestIds: [
      "132"
      // LAST LINE - DO NOT MOVE THIS COMMENT personality
    ]
  },
  {
    name: "values",
    subTestIds: [
      "136"
      // LAST LINE - DO NOT MOVE THIS COMMENT values
    ]
  },
  {
    name: "excel",
    subTestIds: [
      "179",
      "180"
      // LAST LINE - DO NOT MOVE THIS COMMENT excel
    ]
  },
  {
    name: "word",
    subTestIds: [
      "181"
      // LAST LINE - DO NOT MOVE THIS COMMENT word
    ]
  },
  {
    name: "powerpoint",
    subTestIds: [
      "182"
      // LAST LINE - DO NOT MOVE THIS COMMENT powerpoint
    ]
  },
  {
    name: "rcat",
    subTestIds: ["188", "189"]
  },
  {
    name: "ability",
    subTestIds: [
      "190",
      "191",
      "192",
      "193",
      "194",
      "195",
      "196",
      "197",
      "198",
      "199",
      "200",
      "201",
      "202",
      "203",
      "204",
      "205",
      "206",
      "207",
      "208",
      "209",
      "210",
      "211",
      "212",
      "213",
      "214",
      "215"
      // LAST LINE - DO NOT MOVE THIS COMMENT ability
    ]
  }
  // LAST LINE - DO NOT MOVE THIS COMMENT revelianTestToSubTestIdMapNew
];

export const testsToDisplayGraphs: string[] = ["57", "67", "68", "69", "83"];

export const subTestIdToSubTestName: { [key: number]: string } = {
  1: "CCAT",
  2: "CCAT",
  3: "CCAT",
  4: "CBST",
  5: "CBST",
  6: "CBST",
  7: "CPI",
  8: "BMST",
  9: "BVST",
  10: "MRAB",
  11: "MRAB",
  12: "MRABe",
  13: "CAST",
  14: "Memory",
  15: "Selective Attention",
  16: "Mental Rotation",
  17: "Divided Attention",
  18: "CASTe",
  19: "CSAP",
  20: "SalesAP",
  21: "WTMA",
  22: "WTMAe",
  23: "TT",
  24: "TKT",
  25: "Excel 03",
  26: "Word 03",
  27: "Excel 07",
  28: "Word 07",
  29: "CCAT",
  30: "EPP",
  31: "CCAT",
  32: "CBST",
  33: "CCAT",
  34: "CLIK",
  35: "WPP",
  36: "CCAT",
  37: "CCAT",
  38: "CCAT",
  39: "CBSTe",
  40: "CCAT",
  41: "Excel 10",
  42: "Word 10",
  43: "EPP",
  44: "CCAT Rx",
  45: "CCAT UK2",
  46: "CCAT",
  47: "CCAT",
  48: "CCAT",
  49: "CCAT",
  50: "CCAT",
  51: "CCAT",
  52: "WPPe",
  53: "CCAT",
  54: "CCAT",
  55: "CBST CA",
  56: "CCAT CA",
  57: "CCAT",
  58: "Excel 13",
  59: "Word 13",
  60: "CCAT",
  61: "CCAT",
  62: "CCAT",
  63: "CBST",
  64: "CBST",
  65: "CBST Rx",
  66: "AIT",
  67: "UCAT",
  68: "UCATe",
  69: "UCATf",
  70: "CBST EU",
  71: "CCAT UK2 RX",
  72: "PPT 13",
  73: "UCATc",
  74: "UCATj",
  75: "UCATh",
  76: "Excel 16",
  77: "Word 16",
  78: "PPT 16",
  79: "UCATg",
  80: "CBST",
  81: "CBST",
  82: "CBST",
  83: "CBST",
  84: "UCAT Rx",
  85: "UCATp",
  86: "UCATpg",
  87: "UCATb",
  88: "EPPe",
  89: "EPPf",
  90: "EPPp",
  91: "EPPc",
  92: "GAME",
  93: "UCATr",
  94: "UCATa",
  95: "CMRA",
  96: "UCATs",
  97: "UCATm",
  98: "EPPu",
  99: "EPPg",
  100: "WTMA Rx",
  101: "CCAT",
  102: "CMRAs",
  103: "CMRAf",
  104: "UCATcz",
  105: "UCATd",
  106: "UCATu",
  109: "Emotify",
  110: "Cognify",
  111: "CBST Rx",
  112: "Emotify Rx",
  113: "CCAT",
  114: "CBST",
  115: "CMRA",
  116: "UCAT",
  117: "GAME",
  118: "UCATk",
  119: "WSP L",
  120: "WSP",
  121: "Cognify",
  122: "Cognify Rx",
  123: "Cognify Rx",
  124: "CCAT Rx",
  125: "CBST Rx",
  126: "CMRA Rx",
  127: "UCAT Rx",
  128: "Excel 16",
  129: "Word 16",
  130: "PPT 16",
  131: "CCAT cAT",
  133: "UCATc",
  134: "UCATpg",
  135: "CLIK",
  136: "WAA",
  137: "Emotify",
  138: "UCognify",
  139: "UCognify",
  140: "UCognify de",
  141: "UCognify es-es",
  142: "UCATi",
  143: "UCognify pt-br",
  144: "UCognify pt-br",
  145: "UCognify fr-ca",
  146: "UCognify fr-ca",
  150: "UCognify pt-pt",
  151: "UCognify pt-pt",
  152: "EPP ES-LA",
  153: "UCognify es-la",
  154: "UCognify es-la",
  156: "EPP ZH-CN",
  157: "EPP JA",
  155: "EPP PT-BR",
  158: "UCognify de",
  159: "UCognify ko",
  160: "UCognify ko",
  161: "UCognify tr",
  162: "UCognify tr",
  163: "Cognify tr",
  164: "Cognify tr",
  165: "GAMEes-la", // PM requests not to have a space in between the assessment and language code
  166: "UCognify fr-fr",
  167: "UCognify fr-fr",
  168: "UCognify es-es",
  169: "UCognify ja",
  170: "UCognify ja",
  171: "WSPes-la",
  172: "WSP L es-la",
  173: "UCognify zh-cn",
  174: "UCognify zh-cn",
  175: "WSPpt-br",
  176: "WSP L pt-br",
  177: "UCATfr-ca",
  178: "UCATes-la",
  179: "Excel 365",
  180: "Excel 365",
  181: "Word 365",
  182: "PowerPoint 365",
  183: "UCognify it",
  184: "UCognify it",
  185: "EPPit",
  186: "EPPfr-fr",
  187: "WTMAfr-ca",
  188: "RCAT",
  189: "RCAT",
  190: "CLPT-EN",
  191: "CLPTE-EN",
  192: "CLPT-EN es-es",
  193: "CLPT-EN es-la",
  194: "CLPT-EN fr-fr",
  195: "CLPT-EN fr-ca",
  196: "CLPT-EN pt-pt",
  197: "CLPT-EN pt-br",
  198: "CLPT-EN zh-cn",
  199: "CLPT-EN de",
  200: "CLPT-EN it",
  201: "CLPT-EN ja",
  202: "CLPT-EN ko",
  203: "CLPT-EN tr",
  204: "CLPTE-EN es-es",
  205: "CLPTE-EN es-la",
  206: "CLPTE-EN fr-fr",
  207: "CLPTE-EN fr-ca",
  208: "CLPTE-EN pt-pt",
  209: "CLPTE-EN pt-br",
  210: "CLPTE-EN zh-cn",
  211: "CLPTE-EN de",
  212: "CLPTE-EN it",
  213: "CLPTE-EN ja",
  214: "CLPTE-EN ko",
  215: "CLPTE-EN tr",
  216: "EPPnl",
  217: "EPPpl",
  218: "EPPro",
  219: "EPPcs",
  220: "EPPcs",
  221: "EPPsv",
  222: "UCATda",
  223: "UCATro",
  224: "WPPpt-br",
  225: "WTMAfr-fr",
  226: "WTMAes-es",
  227: "WTMA",
  228: "EPP",
  229: "WSPfr-ca",
  230: "WSP L fr-ca",
  231: "WTMAde",
  232: "WTMApl",
  233: "EPPsk",
  234: "WTMAit",
  235: "WTMAcs"
  // LAST LINE - DO NOT MOVE THIS COMMENT subTestIdToSubTestName
};

export const testsWithOnlyOneInstructionPageOnOda = [
  "110",
  "119",
  "120",
  "121",
  "122",
  "123",
  "132",
  "138",
  "139",
  "140",
  "141",
  "143",
  "144",
  "145",
  "146",
  "150",
  "151",
  "153",
  "154",
  "158",
  "159",
  "160",
  "161",
  "162",
  "163",
  "164",
  "166",
  "167",
  "168",
  "169",
  "170",
  "171",
  "172",
  "173",
  "174",
  "175",
  "176",
  "179",
  "180",
  "181",
  "182",
  "183",
  "184",
  "188",
  "189",
  "190",
  "191",
  "192",
  "193",
  "194",
  "195",
  "196",
  "197",
  "198",
  "199",
  "200",
  "201",
  "202",
  "203",
  "204",
  "205",
  "206",
  "207",
  "208",
  "209",
  "210",
  "211",
  "212",
  "213",
  "214",
  "215",
  "229",
  "230"
  // LAST LINE - DO NOT MOVE THIS COMMENT testsWithOnlyOneInstructionPageOnOda
];

/* NOTE: subTestIds not included in this map should be en-us english */
export const subTestLocaleMap: { [key: number]: string } = {
  12: "es-la",
  18: "es-la",
  22: "es-la",
  39: "es-la",
  52: "es-la",
  68: "es-la",
  69: "fr-ca",
  73: "zh-cn",
  74: "ja",
  75: "hi",
  79: "de",
  85: "pl",
  86: "pt-br",
  87: "my",
  88: "es-es",
  89: "fr-ca",
  90: "pt-pt",
  91: "hr",
  93: "ru",
  94: "ar",
  96: "sv",
  97: "mk",
  99: "de",
  102: "es-la",
  103: "fr-ca",
  104: "cs",
  105: "nl",
  106: "uk",
  113: "en-au",
  114: "en-au",
  115: "en-au",
  116: "en-au",
  117: "en-au",
  118: "km",
  124: "en-au",
  125: "en-au",
  126: "en-au",
  127: "en-au",
  128: "en-au",
  129: "en-au",
  130: "en-au",
  133: "zh-cn",
  134: "pt-br",
  140: "de",
  141: "es-es",
  142: "it",
  143: "pt-br",
  144: "pt-br",
  145: "fr-ca",
  146: "fr-ca",
  150: "pt-pt",
  151: "pt-pt",
  152: "es-la",
  153: "es-la",
  154: "es-la",
  155: "pt-br",
  156: "zh-cn",
  157: "ja",
  158: "de",
  159: "ko",
  160: "ko",
  161: "tr",
  162: "tr",
  163: "tr",
  164: "tr",
  165: "es-la",
  166: "fr-fr",
  167: "fr-fr",
  168: "es-es",
  169: "ja",
  170: "ja",
  171: "es-la",
  172: "es-la",
  173: "zh-cn",
  174: "zh-cn",
  175: "pt-br",
  176: "pt-br",
  177: "fr-ca",
  178: "es-la",
  183: "it",
  184: "it",
  185: "it",
  186: "fr-fr",
  187: "fr-ca",
  192: "es-es",
  193: "es-la",
  194: "fr-fr",
  195: "fr-ca",
  196: "pt-pt",
  197: "pt-br",
  198: "zh-cn",
  199: "de",
  200: "it",
  201: "ja",
  202: "ko",
  203: "tr",
  204: "es-es",
  205: "es-la",
  206: "fr-fr",
  207: "fr-ca",
  208: "pt-pt",
  209: "pt-br",
  210: "zh-cn",
  211: "de",
  212: "it",
  213: "ja",
  214: "ko",
  215: "tr",
  216: "nl",
  217: "pl",
  218: "ro",
  219: "cs",
  220: "da",
  221: "sv",
  222: "da",
  223: "ro",
  224: "pt-br",
  225: "fr-fr",
  226: "es-es",
  227: "en-gb",
  228: "en-gb",
  229: "fr-ca",
  230: "fr-ca",
  231: "de",
  232: "pl",
  233: "sk",
  234: "it",
  235: "cs"
  // LAST LINE - DO NOT MOVE THIS COMMENT subTestLocaleMap
};

export const subTestIdToTestIdMap: { [key: number]: number } = {
  1: 10,
  2: 10,
  3: 10,
  4: 12,
  5: 12,
  6: 12,
  7: 13,
  8: 12,
  9: 12,
  10: 14,
  11: 14,
  12: 14,
  13: 23,
  14: 25,
  15: 26,
  16: 25,
  17: 26,
  18: 23,
  19: 15,
  20: 24,
  21: 16,
  22: 16,
  23: 17,
  24: 18,
  25: 19,
  26: 20,
  27: 21,
  28: 22,
  29: 10,
  30: 29,
  31: 10,
  32: 12,
  33: 10,
  34: 30,
  35: 31,
  36: 10,
  37: 10,
  38: 10,
  39: 12,
  40: 10,
  41: 32,
  42: 33,
  43: 34,
  44: 10,
  45: 10,
  46: 10,
  47: 10,
  48: 10,
  49: 10,
  50: 10,
  51: 10,
  52: 31,
  53: 10,
  54: 10,
  55: 12,
  56: 10,
  57: 10,
  58: 35,
  59: 36,
  60: 10,
  61: 10,
  62: 10,
  63: 12,
  64: 12,
  65: 12,
  66: 37,
  67: 37,
  68: 37,
  69: 37,
  70: 12,
  71: 10,
  72: 38,
  73: 37,
  74: 37,
  75: 37,
  76: 39,
  77: 40,
  78: 41,
  79: 37,
  80: 12,
  81: 12,
  82: 12,
  83: 12,
  84: 37,
  85: 37,
  86: 37,
  87: 37,
  88: 34,
  89: 34,
  90: 34,
  91: 34,
  92: 42,
  93: 37,
  94: 37,
  95: 43,
  96: 37,
  97: 37,
  98: 34,
  99: 34,
  100: 16,
  101: 10,
  102: 43,
  103: 43,
  104: 37,
  105: 37,
  106: 37,
  107: 10,
  108: 10,
  109: 44,
  110: 45,
  111: 12,
  112: 44,
  113: 10,
  114: 12,
  115: 43,
  116: 37,
  117: 42,
  118: 37,
  119: 46,
  120: 46,
  121: 45,
  122: 45,
  123: 45,
  124: 10,
  125: 12,
  126: 43,
  127: 37,
  128: 39,
  129: 40,
  130: 41,
  131: 47,
  132: 48,
  133: 37,
  134: 37,
  135: 49,
  136: 50,
  137: 51,
  138: 52,
  139: 52,
  140: 52,
  141: 52,
  142: 37,
  143: 52,
  144: 52,
  145: 52,
  146: 52,
  150: 52,
  151: 52,
  152: 34,
  153: 52,
  154: 52,
  155: 34,
  156: 34,
  157: 34,
  158: 52,
  159: 52,
  160: 52,
  161: 52,
  162: 52,
  163: 45,
  164: 45,
  165: 42,
  166: 52,
  167: 52,
  168: 52,
  169: 52,
  170: 52,
  171: 46,
  172: 46,
  173: 52,
  174: 52,
  175: 46,
  176: 46,
  177: 37,
  178: 37,
  179: 53,
  180: 53,
  181: 54,
  182: 55,
  183: 52,
  184: 52,
  185: 34,
  186: 34,
  187: 16,
  188: 56,
  189: 56,
  190: 57,
  191: 57,
  192: 57,
  193: 57,
  194: 57,
  195: 57,
  196: 57,
  197: 57,
  198: 57,
  199: 57,
  200: 57,
  201: 57,
  202: 57,
  203: 57,
  204: 57,
  205: 57,
  206: 57,
  207: 57,
  208: 57,
  209: 57,
  210: 57,
  211: 57,
  212: 57,
  213: 57,
  214: 57,
  215: 57,
  216: 34,
  217: 34,
  218: 34,
  219: 34,
  220: 34,
  221: 34,
  222: 37,
  223: 37,
  224: 31,
  225: 16,
  226: 16,
  227: 16,
  228: 34,
  229: 46,
  230: 46,
  231: 16,
  232: 16,
  233: 34,
  234: 16,
  235: 16
  // LAST LINE - DO NOT MOVE THIS COMMENT subTestIdToTestIdMap
};
