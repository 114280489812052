import React, { Component } from "react";
import { Container, Row, Col, Alert, Button } from "react-bootstrap";
import { BarLoader } from "react-spinners";
import { nodeApiURL } from "../../utils/constants";
import { TestEventConsumer } from "../../Contexts/TestEventContext";
import { PersonalityTestAnswerData } from "../../Interfaces/AnswerData";
import ProgressBar from "../../Components/ProgressBar";
import NextButton from "../../Components/NextButton";
import InterruptModal from "../../Components/InterruptModal";
import { MdSignalWifiOff } from "react-icons/md";
import { shuffleArray, isEventExpired } from "../../utils/shared";
import { navigate } from "@reach/router";

interface Props {
  testData: any;
  subTestId: string;
  pageNumberToResume: number;
  endSubTest: () => void;
}
interface State {
  pageNumber: number;
  answerObject: any;
  error: string | null;
  showInterruptModal: boolean;
  failCounter: number;
  didReconnectFail: boolean;
  disableNextButton: boolean;
  questionArray: any;
}

export default class PersonalityTest extends Component<Props, State> {
  state: State = {
    pageNumber: 1,
    answerObject: {},
    error: null,
    showInterruptModal: false,
    failCounter: 0,
    didReconnectFail: false,
    disableNextButton: false,
    questionArray: []
  };

  // define the context type so that we can use this.context throughout the class
  static contextType: any = TestEventConsumer;

  componentDidMount() {
    const startingIndex =
      this.state.pageNumber === 1 ? 0 : this.state.pageNumber * 5 - 5;
    const endingIndex =
      this.state.pageNumber === 1 ? 5 : this.state.pageNumber * 5;
    const questionArray = shuffleArray(
      this.props.testData.questions.slice(startingIndex, endingIndex)
    );
    this.setState({ questionArray });
  }

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    return nextProps.pageNumberToResume > 0 && prevState.pageNumber === 1
      ? { pageNumber: nextProps.pageNumberToResume }
      : {};
  }

  handleAnswer = (event: React.ChangeEvent<HTMLInputElement>) => {
    // extract the answer value and the question id from the event object
    const answer: string = event.target.value;
    const id: string =
      event.target.dataset.id !== undefined ? event.target.dataset.id : "";

    // deconstruct the exsiting answerObject in state, adding (or replacing) the new values
    const answerObject = { ...this.state.answerObject, [id]: answer };

    // set the new answerObject to state
    this.setState({ answerObject }, () => {
      if (this.state.error !== null) {
        this.setState({ error: null });
      }
      if (this.state.disableNextButton) {
        this.setState({ disableNextButton: false });
      }
    });
  };

  handleNext = () => {
    if (!isEventExpired(this.context.eventExpirationDate)) {
      this.setState({ disableNextButton: true });
      // scroll to top of screen for the next question
      requestAnimationFrame(() => {
        window.scrollTo(0, 0);
      });

      // verify that all questions on the current page have been answered
      if (Object.keys(this.state.answerObject).length === 5) {
        const answerData: PersonalityTestAnswerData = {
          testEventId: this.context.testEventId,
          subTestId: this.props.subTestId,
          answerObject: this.state.answerObject
        };
        this.sendAnswersToRedis(answerData);
      } else {
        const answerError =
          this.props.testData?.subTestText?.answerError ??
          "You must respond to all of the prompts.";
        this.setState({ error: answerError });
      }
    } else {
      // if the event has expired, we need to update the error message, and navigate to the overview page immediately
      this.context.updateExpiredMessage();
      navigate("/overview");
    }
  };
  handleRetryButton = () => {
    this.setState({ didReconnectFail: false }, () => {
      this.handleRedisFailure();
    });
  };

  handleRedisFailure = () => {
    this.setState(state => ({
      showInterruptModal: true,
      failCounter: state.failCounter + 1
    }));
    const rootElement = document.getElementById("root");
    if (rootElement !== null) {
      rootElement.style.filter = "blur(10px)";
    }
    if (this.state.failCounter < 30) {
      setTimeout(this.handleNext, 1000);
    } else {
      this.setState({ didReconnectFail: true, failCounter: 0 });
    }
  };

  sendAnswersToRedis = async (
    answerData: PersonalityTestAnswerData
  ): Promise<boolean> => {
    // set variable to the root DOM element, so we can remove the blur filter once connection is re-established
    const rootElement = document.getElementById("root");

    try {
      const response = await fetch(`${nodeApiURL}/sendPersonalityAnswers`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.context.token
        },
        body: JSON.stringify(answerData)
      });

      const responseData = await response.json();

      if (response.status !== 200) {
        this.handleRedisFailure();
      } else {
        // remove the blur filter that we set on the root DOM element when showing the InterruptModal
        if (rootElement !== null) {
          rootElement.style.filter = "none";
        }

        // determine if test taker is on the last page, and if so, end the subTest
        if (
          this.state.pageNumber ===
          this.props.testData.questions.length / 5
        ) {
          this.props.endSubTest();
        } else {
          const startingIndex = responseData.pageNumberToResume * 5 - 5;
          const endingIndex = responseData.pageNumberToResume * 5;
          const questionArray = shuffleArray(
            this.props.testData.questions.slice(startingIndex, endingIndex)
          );

          // reset the state and move to the next page
          this.setState({
            pageNumber: responseData.pageNumberToResume,
            answerObject: {},
            showInterruptModal: false,
            failCounter: 0,
            disableNextButton: false,
            questionArray
          });
        }
      }
    } catch (error) {
      this.handleRedisFailure();
    }
    return true;
  };

  render() {
    const { testData } = this.props;
    /* ---------- The AnswerOption Component --------- */

    const Question = this.state.questionArray.map(
      (key: string, index: number) => {
        const questionStem = this.state.questionArray[index].question;
        return (
          <Row key={index} tabIndex={0}>
            <Col xl={6} lg={6} md={6} sm={12} className="personality-row">
              <h4 dangerouslySetInnerHTML={{ __html: questionStem }} />
            </Col>
            <Col xl={6} lg={6} md={6} sm={12} className="personality-row">
              <fieldset>
                <legend>{questionStem}</legend>
                <table className="selectScale selectScaleButtons">
                  <tbody>
                    <tr>
                      <td className="personality-label-cell">
                        <label
                          htmlFor={`answer_1_${this.state.questionArray[index].id}`}
                          className={
                            this.state.answerObject[
                              this.state.questionArray[index].id
                            ] === "1"
                              ? "personality-label selected-answer"
                              : "personality-label"
                          }
                        >
                          <input
                            type="radio"
                            value="1"
                            data-id={this.state.questionArray[index].id}
                            onChange={this.handleAnswer}
                            checked={
                              this.state.answerObject[
                                this.state.questionArray[index].id
                              ] === "1"
                            }
                            id={`answer_1_${this.state.questionArray[index].id}`}
                            aria-labelledby="labelTop1"
                          />
                          <div className="d-sm-block d-md-none">
                            <div className="small-text">
                              {testData.subTestText &&
                              testData.subTestText.alwaysTrue
                                ? testData.subTestText.alwaysTrue
                                : "Always True"}
                            </div>
                          </div>
                        </label>
                      </td>
                      <td className="personality-label-cell">
                        <label
                          htmlFor={`answer_2_${this.state.questionArray[index].id}`}
                          className={
                            this.state.answerObject[
                              this.state.questionArray[index].id
                            ] === "2"
                              ? "personality-label selected-answer"
                              : "personality-label"
                          }
                        >
                          <input
                            type="radio"
                            value="2"
                            data-id={this.state.questionArray[index].id}
                            onChange={this.handleAnswer}
                            checked={
                              this.state.answerObject[
                                this.state.questionArray[index].id
                              ] === "2"
                            }
                            id={`answer_2_${this.state.questionArray[index].id}`}
                            aria-labelledby="labelTop2"
                          />
                          <div className="d-sm-block d-md-none">
                            <div className="small-text">
                              {testData.subTestText &&
                              testData.subTestText.mostlyTrue
                                ? testData.subTestText.mostlyTrue
                                : "Mostly True"}
                            </div>
                          </div>
                        </label>
                      </td>
                      <td className="personality-label-cell">
                        <label
                          htmlFor={`answer_3_${this.state.questionArray[index].id}`}
                          className={
                            this.state.answerObject[
                              this.state.questionArray[index].id
                            ] === "3"
                              ? "personality-label selected-answer"
                              : "personality-label"
                          }
                        >
                          <input
                            type="radio"
                            value="3"
                            data-id={this.state.questionArray[index].id}
                            onChange={this.handleAnswer}
                            checked={
                              this.state.answerObject[
                                this.state.questionArray[index].id
                              ] === "3"
                            }
                            id={`answer_3_${this.state.questionArray[index].id}`}
                            aria-labelledby="labelTop3"
                          />
                          <div className="d-sm-block d-md-none">
                            <div className="small-text">
                              {testData.subTestText &&
                              testData.subTestText.sometimesTrue
                                ? testData.subTestText.sometimesTrue
                                : "Sometimes True and Sometimes False"}
                            </div>
                          </div>
                        </label>
                      </td>
                      <td className="personality-label-cell">
                        <label
                          htmlFor={`answer_4_${this.state.questionArray[index].id}`}
                          className={
                            this.state.answerObject[
                              this.state.questionArray[index].id
                            ] === "4"
                              ? "personality-label selected-answer"
                              : "personality-label"
                          }
                        >
                          <input
                            type="radio"
                            value="4"
                            data-id={this.state.questionArray[index].id}
                            onChange={this.handleAnswer}
                            checked={
                              this.state.answerObject[
                                this.state.questionArray[index].id
                              ] === "4"
                            }
                            id={`answer_4_${this.state.questionArray[index].id}`}
                            aria-labelledby="labelTop4"
                          />
                          <div className="d-sm-block d-md-none">
                            <div className="small-text">
                              {testData.subTestText &&
                              testData.subTestText.mostlyFalse
                                ? testData.subTestText.mostlyFalse
                                : "Mostly False"}
                            </div>
                          </div>
                        </label>
                      </td>
                      <td className="personality-label-cell">
                        <label
                          htmlFor={`answer_5_${this.state.questionArray[index].id}`}
                          className={
                            this.state.answerObject[
                              this.state.questionArray[index].id
                            ] === "5"
                              ? "personality-label selected-answer"
                              : "personality-label"
                          }
                        >
                          <input
                            type="radio"
                            value="5"
                            data-id={this.state.questionArray[index].id}
                            onChange={this.handleAnswer}
                            checked={
                              this.state.answerObject[
                                this.state.questionArray[index].id
                              ] === "5"
                            }
                            id={`answer_5_${this.state.questionArray[index].id}`}
                            aria-labelledby="labelTop5"
                          />
                          <div className="d-sm-block d-md-none">
                            <div className="small-text">
                              {testData.subTestText &&
                              testData.subTestText.alwaysFalse
                                ? testData.subTestText.alwaysFalse
                                : "Always False"}
                            </div>
                          </div>
                        </label>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </fieldset>
            </Col>
          </Row>
        );
      }
    );
    return (
      <Container id="test-content">
        <Row style={{ marginTop: "50px" }}>
          <Col xl={12} lg={12} md={12} sm={12}>
            <ProgressBar
              width={
                (this.state.pageNumber / (testData.questions.length / 5)) * 100
              }
            />
            <p className="questionCounter">
              {this.props.testData.subTestText &&
              this.props.testData.subTestText.page ? (
                <span
                  dangerouslySetInnerHTML={{
                    __html: this.props.testData.subTestText.page
                      .replace(
                        "{[PAGE_NUMBER]}",
                        "<strong>" + this.state.pageNumber + "</strong>"
                      )
                      .replace(
                        "{[TOTAL_PAGES]}",
                        "<strong>" + testData.questions.length / 5 + "</strong>"
                      )
                  }}
                />
              ) : (
                <span>
                  Page <strong>{this.state.pageNumber}</strong> /{" "}
                  <strong>{testData.questions.length / 5}</strong>
                </span>
              )}
            </p>
            <hr />
            {this.state.error !== null ? (
              <Row>
                <Col>
                  <Alert variant="danger">{this.state.error}</Alert>
                </Col>
              </Row>
            ) : null}
            <div className="d-none d-md-block">
              <Row>
                <Col xl={6} lg={6} md={6} sm={12}>
                  &nbsp;
                </Col>
                <Col xl={6} lg={6} md={6} sm={12} aria-hidden={true}>
                  <table className="selectScale" id="select-scale-header">
                    <thead>
                      <tr>
                        <th scope="col" id="labelTop1">
                          {testData.subTestText &&
                          testData.subTestText.alwaysTrue
                            ? testData.subTestText.alwaysTrue
                            : "Always True"}
                        </th>
                        <th scope="col" id="labelTop2">
                          {testData.subTestText &&
                          testData.subTestText.mostlyTrue
                            ? testData.subTestText.mostlyTrue
                            : "Mostly True"}
                        </th>
                        <th scope="col" id="labelTop3">
                          {testData.subTestText &&
                          testData.subTestText.sometimesTrue
                            ? testData.subTestText.sometimesTrue
                            : "Sometimes True and Sometimes False"}
                        </th>
                        <th scope="col" id="labelTop4">
                          {testData.subTestText &&
                          testData.subTestText.mostlyFalse
                            ? testData.subTestText.mostlyFalse
                            : "Mostly False"}
                        </th>
                        <th scope="col" id="labelTop5">
                          {testData.subTestText &&
                          testData.subTestText.alwaysFalse
                            ? testData.subTestText.alwaysFalse
                            : "Always False"}
                        </th>
                      </tr>
                    </thead>
                  </table>
                </Col>
              </Row>
            </div>
            {Question}
            <div className="d-none d-md-block">
              <Row>
                <Col xl={6} lg={6} md={6} sm={12}>
                  &nbsp;
                </Col>
                <Col xl={6} lg={6} md={6} sm={12} aria-hidden={true}>
                  <table className="selectScale" id="select-scale-footer">
                    <thead>
                      <tr>
                        <th scope="col">
                          {testData.subTestText &&
                          testData.subTestText.alwaysTrue
                            ? testData.subTestText.alwaysTrue
                            : "Always True"}
                        </th>
                        <th scope="col">
                          {testData.subTestText &&
                          testData.subTestText.mostlyTrue
                            ? testData.subTestText.mostlyTrue
                            : "Mostly True"}
                        </th>
                        <th scope="col">
                          {testData.subTestText &&
                          testData.subTestText.sometimesTrue
                            ? testData.subTestText.sometimesTrue
                            : "Sometimes True and Sometimes False"}
                        </th>
                        <th scope="col">
                          {testData.subTestText &&
                          testData.subTestText.mostlyFalse
                            ? testData.subTestText.mostlyFalse
                            : "Mostly False"}
                        </th>
                        <th scope="col">
                          {testData.subTestText &&
                          testData.subTestText.alwaysFalse
                            ? testData.subTestText.alwaysFalse
                            : "Always False"}
                        </th>
                      </tr>
                    </thead>
                  </table>
                </Col>
              </Row>
            </div>
            <br />
            <NextButton
              handler={this.handleNext}
              buttonLabel={
                testData.subTestText && this.props.testData.subTestText.continue
                  ? this.props.testData.subTestText.continue
                  : "Continue"
              }
              disableNextButton={this.state.disableNextButton}
            />
          </Col>
        </Row>
        {this.state.showInterruptModal ? (
          <InterruptModal>
            <Col className="text-center">
              <h1 style={{ fontSize: "150px", margin: "50px 0" }}>
                <MdSignalWifiOff />
              </h1>
              <h3>
                <strong>Test Paused</strong>
              </h3>
              {!this.state.didReconnectFail ? (
                <div className="text-left">
                  <p>There was an error while trying to save your answer.</p>
                  <p>Please wait while we try to reconnect.</p>
                </div>
              ) : (
                <div className="text-left">
                  <p>
                    We were unable to reconnect. Please try again later or from
                    a different device.
                  </p>
                </div>
              )}
              {!this.state.didReconnectFail ? (
                <BarLoader height={6} width={150} color={"#428bca"} />
              ) : null}
              {this.state.didReconnectFail ? (
                <div className="text-center">
                  <Button
                    variant="success"
                    id="retry-connection-button"
                    onClick={this.handleRetryButton}
                  >
                    Retry
                  </Button>
                </div>
              ) : null}
            </Col>
          </InterruptModal>
        ) : null}
      </Container>
    );
  }
}
