import React, { FunctionComponent, useContext, useState } from "react";
import { TestEventContext } from "../Contexts/TestEventContext";
import useGetData from "../CustomHooks/useGetData";
import useGetToken from "../CustomHooks/useGetToken";
import MultipleChoiceTest from "../Tests/TestDisplays/MultipleChoice/MultipleChoiceTest";
import PersonalityTest from "../Tests/TestDisplays/PersonalityTest";
import { getAllTests } from "../utils/Reference/SubTestDataMaps";
import { TestType } from "../utils/Reference/TestType";

const Direct: FunctionComponent = () => {
  const { subTestId, eventId, returnUrl } = useGetToken();
  const testLibrary = getAllTests();
  const testType = testLibrary[subTestId];
  const [isComplete, setIsComplete] = useState(false);
  const { data, isLoading, error } = useGetData({
    eventId,
    subTestId,
    testType
  });
  const testEventContext = useContext(TestEventContext);

  const endTestHandler = () => {
    setIsComplete(() => true);
    window.location.replace(returnUrl);
  };

  if (error) {
    // TODO - determine if we want <ErrorPage /> here
    return error instanceof Error ? (
      <div>
        {error.name} : {error.message}
      </div>
    ) : (
      <div>Unknown Error</div>
    );
  }

  if (isLoading || isComplete) {
    return <div>Loading...</div>;
  }

  if (data) {
    testEventContext.testEventData = data;
    testEventContext.eventId = data.eventId;
    testEventContext.testEventId = data.testEventId;
    testEventContext.token = data.token;

    return (
      <div
        style={{
          minHeight: "100vh",
          height: "100%",
          backgroundColor: "#FFF",
          margin: "-16px",
          padding: "16px"
        }}
      >
        {determineAssessment(testType, {
          testData: data.tests[subTestId].details,
          subTestId,
          startingQuestion: data.lastQuestionNumber - 1,
          pageNumberToResume: data.pageNumberToResume,
          timeRemaining: data.tests[subTestId].details.timeAllowed,
          dataLoaded: true,
          endSubTest: endTestHandler
        })}
      </div>
    );
  }

  return <div>Error!</div>;
};

function determineAssessment(
  testType: string,
  props: Partial<{
    testData: any;
    subTestId: string;
    startingQuestion: number;
    timeRemaining: number;
    dataLoaded: boolean;
    endSubTest: () => void;
    simulationTimeAllowed?: number;
    isCLIKv2?: boolean;
    pageNumberToResume?: number;
  }>
): JSX.Element {
  switch (testType) {
    case TestType.MULTIPLE_CHOICE:
      return <MultipleChoiceTest {...props} />;
    case TestType.PERSONALITY:
      return <PersonalityTest {...props} />;
    default:
      return <div>Error!</div>;
  }
}

export default Direct;
